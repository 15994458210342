export const normalizeTypeName = (inputString: String) => {
    return inputString
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9\-]/g, '-')
    .replace(/\-+/g, '-');
}

export const capitalize=(inputString:String)=> {
    return inputString.charAt(0).toUpperCase()+inputString.slice(1)
}

export const getAreaSuffix = (areaName:string, capitalize=true) => {
    if(areaName === 'near-me'){
        return capitalize ? 'Near Me' : 'near me'
    } else {
        return `in ${areaName}`
    }
}