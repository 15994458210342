import { LocationService } from './../services/location.service';
import { environment } from '@/environments/environment';
import { InstituteService } from './../services/institute.service';
import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CategoryService } from '../services/category.service';
import { capitalize, getAreaSuffix, normalizeTypeName } from '../utils/string';
import { CityService } from '../services/city.service';
import { ToastService } from '../services/toast.service';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { SearchComponent } from '../search/search.component';
import { NoDataFoundComponent } from '../shared/no-data-found/no-data-found.component';
import { NgbAccordionModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { PopularCardComponent } from '../shared/popular-card/popular-card.component';
import { RouterLink } from '@angular/router';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { TrendingSearchesComponent } from '../shared/trending-searches/trending-searches.component';
import { FaqComponent } from '../shared/faq/faq.component';
import { FaqService } from '../services/faq/faq.service';
import { MetaService } from '../services/meta.service';
import { forkJoin, map, Observable, of } from 'rxjs';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import City from '../interface/city';
import Category from '../interface/category';
import { FilterComponent } from "../filter/filter.component";
import Faq from '../interface/faq';


@Component({
  selector: 'app-listing',
  standalone: true,
  imports: [
    AppHeaderComponent,
    AppFooterComponent,
    SearchComponent,
    NoDataFoundComponent,
    NgbCarouselModule,
    NgbAccordionModule,
    PopularCardComponent,
    RouterLink,
    TrendingSearchesComponent,
    FaqComponent,
    AsyncPipe,
    FilterComponent
],
  templateUrl: './listing.component.html',
  styleUrl: './listing.component.scss',
})
export class ListingComponent implements OnChanges {
  @Input() localityId!:string
  @Input() typeId!: string;
  @Input() cityId!: string;
  @Input() longitude!:any
  @Input() latitude!:any
  @Input('city') cityRouteParam!: string;
  @Input('type') typeRouteParam!: any;

  allInstitutes: any[] = [];
  allCities: City[] = []
  trendingSearchItems$: Observable<any[]>
  SHIKSHATAMBUCKET_URL = environment.BUCKET_URL;
  searchMode: string;
  popularInstitutes: any[] = [];
  faq$: Observable<any[]>
  loading: boolean = false
  h1Tag:string;
  h2Tag:[]=[]
  cityName: string = ''
  categoryName: string = ''
  localityName: string = ''

  localities:any[]=[];
  localititesFilter: any = {
    page: 1,
    limit: 500
  }
  totalLocalities: number = 0

  classes: any[] = [];
  subTypes:any[]=[]
  filter: any={}

  constructor(
    private instituteService: InstituteService,
    private locationService: LocationService,
    private categoryService: CategoryService,
    private cityService: CityService,
    private toastService: ToastService,
    private faqService:FaqService,
    private metaService:MetaService,
    @Inject(DOCUMENT) private dom:Document 
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['typeId'] || changes['cityId'] || changes['cityRouteParam']) {
        this.localities=[]
        this.subTypes=[]
        this.classes=[]
        this.localityName = ''
        this.filter = {}
        
      //cityId does not change when selecting "Near Me" option. Hence monitoring change on `city` as well
      this.allInstitutes = [];
      
      if (this.localityId) {
        this.filter = { ...this.filter, locality: this.localityId };
      }

      // Wait for city list and category list to populate. Then do any further processing. This was done so that city and category name are rendered correctly in SSR generated HTML.
      forkJoin({
        categoriesResponse: this.categoryService.allCategories$,
        cities: this.cityService.allCities$
      }).subscribe(async ({ categoriesResponse, cities }) => {
        this.allCities = cities // Used by `fetchAllInstitutes`

        this.setCityAndCategoryName(categoriesResponse.data, cities)

        if (this.cityRouteParam === 'near-me') {
          this.fetchAllInstitutes()
        } else {
          await this.fetchAllInstitutes(true);
          this.fetchLocalities()
        }
        this.generateTrendingSearches(categoriesResponse.data)
        this.setMetaInfo()
      });
      this.getFaq()    
    }
 }

  setCityAndCategoryName(categories:Category[], cities:City[]){
    const foundCategory = categories.find(categoryObj => categoryObj._id === this.typeId);
    if (foundCategory) {
      this.categoryName = foundCategory.type;
    } else {
      this.categoryName = this.typeRouteParam;
    }
  
    const foundCity = cities.find(cityObj => cityObj._id === this.cityId);
    if (foundCity) {
      this.cityName = foundCity.name;
    } else {
      this.cityName = this.cityRouteParam;
    }
  }

  setLocalityName(localityId: string){
    if(localityId){
      const selectedLocalityObj = this.localities.find(item => item._id === localityId)
      if(selectedLocalityObj){
        this.localityName = selectedLocalityObj.name
      } else {
        this.localityName = ''
      }
    } else {
      this.localityName = ''
    }
  }
 
  async fetchAllInstitutes(resetFilters=false) {
    this.loading = true
    let payload: any = {
      type: this.typeId,
      city: this.cityId,
      ...this.filter
    };

    if (this.cityRouteParam === 'near-me') {
      try {
        const data = await this.getCityName(this.latitude, this.longitude);
        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          const cityComponent = addressComponents.find(
            (component: any) =>
              component.types.includes('locality') ||
              component.types.includes('administrative_area_level_1')
          );

          const matchedCity = this.allCities.find(
            (city: any) =>
              city.name.toLowerCase() === cityComponent.long_name.toLowerCase()
          );

          if (matchedCity) {
            payload.city = matchedCity._id;
            payload.searchMode = 'LOCATE_ME';
            payload.lat = this.latitude;
            payload.lng = this.longitude;
          } else {
            this.loading = false
            throw new Error('GuruNearMe has not reached your city yet.')
          }
        } else {
          console.log("Tried fetching city name from maps API. It returned this response: ",data )
          this.loading = false
          throw new Error('Unable to fetch location data.')
        }
      } catch(error:any){
        this.loading = false
        this.toastService.showError(error.message)
        return
      }
    }

    this.instituteService.aggregateInstitutes(payload).toPromise().then((response) => {
      this.allInstitutes = response.data;
      this.popularInstitutes=response.popularInYourArea
      this.loading = false
      if(resetFilters){
        response.typeDetailsForFilter
        .forEach((item:any) => {
            this.classes=item.classes;
            this.subTypes=item.subTypes;
        });
      }
    });
  }

  onDirectionsClick(coordinates: number[]) {
    let url;

    if (this.locationService.permissionState == 'granted') {
      // If location of the user is available, create URL that shows direction from user's location
      const { latitude: userLatitude, longitude: userLongitude } =
        this.locationService;

      url = `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${coordinates[1]},${coordinates[0]}`;
    } else {
      // If location is not available, simply open the institute location on Google maps.
      url = `https://www.google.com/maps/dir/?api=1&&destination=${coordinates[1]},${coordinates[0]}`;
    }
    window.open(url, '_blank');
  }

  generateTrendingSearches(categories:Category[]) {
    this.trendingSearchItems$ = of(categories.map(category => {
        const suffix = getAreaSuffix(this.cityName)
        return {
          ...category,
          route: `/listing/${this.cityRouteParam}/${normalizeTypeName(category.type)}`,
          queryParams: this.cityRouteParam=="near-me"
        ? { typeId: category._id, latitude:this.latitude, longitude:this.longitude } 
        : { cityId: this.cityId, typeId: category._id },
          name: `${category.type} ${suffix}`,
        }
      })
    )
  }



  getDistances(distance: number) {
    if (distance === undefined || distance === -1) {
      return 'Get Directions'; // Default message
    }

    // If distance is valid, call the service method
    return this.instituteService.getDistanceText(distance);
  }

  getTeacherAndExperienceText(institute: any) {
    return this.instituteService.getTeacherAndExperienceText(
      institute.teachersCount,
      institute.experienceMin,
      institute.experienceMax
    );
  }

  getCityName(lat: Number, lng: Number) {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBiNvL3i-9eDytiDIYaT1mbWShgMppF5i0`;
    return fetch(apiUrl).then((response) => response.json());
  }

  getFaq(){
    this.faq$=this.faqService.faq$.pipe(
      map((response:any) => response[this.typeRouteParam]),
      map((faqList) => {
        if(faqList){
          return faqList.map((faqObj: Faq) => {
            let question:any  = faqObj.question
            let answer:any = faqObj.answer

            if(typeof question === 'function'){
              question = question(this.cityName)
            }
            if(typeof answer === 'function'){
              answer = answer(this.cityName)
            }
            return {
              question,
              answer
            }
          })
        }
      })
    )
  }

  setMetaInfo(){
   this.metaService.getMeta().then((response)=>{
    const metaInfo = response[this.typeRouteParam]
    const areaSuffix = getAreaSuffix(this.localityName ? `${this.localityName}, ${this.cityName}`: this.cityName)

    // If the meta.json file does not contain information for meta of a particular category, add some fallback value
    let modifiedTitle = `${capitalize(this.categoryName.toLowerCase())} ${areaSuffix}`
    let modifiedDescription = modifiedTitle
    let h1Tag = modifiedTitle
    let h2TagList = []
    let logo =''
    
    if(metaInfo){
      const {title,description ,h1 ,h2, skipAreaSuffix} = metaInfo

      // City Name was added so that listing page of 2 different cities will have different descriptions and titils
      modifiedDescription = `${description} - ${this.cityName}` 
      modifiedTitle = `${title} - ${this.cityName}`

      if(skipAreaSuffix){
        h1Tag = h1
      } else {
        h1Tag = `${h1} ${areaSuffix}`;
      }
      h2TagList = h2.slice(0, 3)
    }
    if(this.dom.location.origin.includes('newui')){
      logo= `https://newui.gurunearme.com/src/assets/images/${this.categoryName}.png`;
   }else{
      logo= `https://www.gurunearme.com/src/assets/images/${this.categoryName}.png`;
   }
    this.metaService.setMeta(modifiedTitle,modifiedDescription)
    this.metaService.setOgTag(modifiedTitle,modifiedDescription,logo)
    this.metaService.setTwitterCardTag(modifiedTitle,modifiedDescription,logo)
    this.h1Tag=h1Tag
    this.h2Tag=h2TagList
   })
    
  }

  handleLocalitySearch(localitySearchTerm: string){
    this.localititesFilter = {
      limit: 500,
      page: 1, // Reset page no on search
      name: localitySearchTerm
    }
    this.fetchLocalities()
  }

  handleLocalityLoadMore(){
    this.localititesFilter = {
      ...this.localititesFilter,
      page: this.localititesFilter.page + 1 // Fetch next page
    }

    this.fetchLocalities(true)
  }

  fetchLocalities(mergeOld:boolean = false){
    this.cityService.fetchlocality({
      city:this.cityId,
      ...this.localititesFilter
    }).toPromise()
    .then((response) => {

      if(mergeOld){

        // Get old locality list and remove `Other` from it.
        const oldLocalities = this.localities.filter(localityObj => localityObj.name !== 'Other')

        // Create updated list with old and new entries
        this.localities = [...oldLocalities, ...response.data]

      }else {
        this.localities = response.data
      }

      if(this.localityId){
        // If there is a pre-selected locality, set it's name and update the h1 text.
        this.setLocalityName(this.localityId)
        this.setMetaInfo()
      }

      // Server was not sending total count, so, had to improvise and write this weird if-else block to correctly calculate the total count of localitites on server.
      if(response.currentPage < response.totalPages){
        // Added 1 for the extra option named `Other`
        this.totalLocalities = (response.totalPages * this.localititesFilter.limit) + 1
      } else {
        // Calculate accurate count of localities when last page is reached
        const countTillSecondLastPage = ((response.totalPages-1) * this.localititesFilter.limit)
        
        this.totalLocalities = countTillSecondLastPage + response.data.length
      }

      
    })
    .catch((error) => {
      console.log(error.message);
    });
  }

  handleSelectedLocalityId(selectedId: string) {
    this.filter={...this.filter,locality:selectedId}
    this.fetchAllInstitutes()

    // Set locality name to change h1 on the page
    this.setLocalityName(selectedId)
    this.setMetaInfo()
  }
  handleSelectedClassId(selectedId: string) {
    this.filter={...this.filter,classes:selectedId}
    this.fetchAllInstitutes()
  }
  handleSelectedSubTypeId(selectedId: string) {
    this.filter={...this.filter,subType:selectedId}
    this.fetchAllInstitutes()
  }
}
