import { Component, Input} from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppHeaderComponent } from "../shared/app-header/app-header.component";
import { AppFooterComponent } from "../shared/app-footer/app-footer.component";

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [RouterLink, AppHeaderComponent, AppFooterComponent],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class PageNotFoundComponent {
  @Input() description: string='Page not found';
  @Input() title:string='Error'
}
