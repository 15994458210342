import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ListingComponent } from './listing/listing.component';
import { InstituteDetailComponent } from './institute-detail/institute-detail.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { TermsComponent } from './shared/terms/terms.component';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { BlogsHomeComponent } from './blogs-home/blogs-home.component';
import { BlogsByCategoryComponent } from './blogs-by-category/blogs-by-category.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogSearchResultsComponent } from './blog-search-results/blog-search-results.component';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { PageNotFoundComponent } from './error/error.component';

export const routes: Routes = [
  { 
    path: 'listing/:city/:type', 
    component: ListingComponent 
  },
  {
    path: 'institute/:urlCode',
    component: InstituteDetailComponent
  },
  {
    path: 'enquiry',
    component: EnquiryComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'report-issue',
    component: ReportIssueComponent
  },
  // Blog section URLs start
  {
    path: 'blogs',
    component: BlogsHomeComponent,
  },
  {
    path: 'blogs/category/:categorySlug',
    component: BlogsByCategoryComponent,
  },
  {
    path: 'blogs/article/:blogSlug',
    component: BlogDetailsComponent,
  },
  {
    path: 'blogs/search',
    component: BlogSearchResultsComponent,
  },
  // Blog section URLs end
  /* 
  WARNING: DO NOT ADD NEW ROUTES BELOW THIS SECTION. ADD NEW ROUTES ABOVE THIS LINE!!!
  */
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  { path: '**', component:PageNotFoundComponent, 
    data :{ description : 'Page not found',title:'404'}
  },
];
