import { response } from 'express';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private dom:Document // In SSR browser's `document` variable is not available. So, use this instead. Taken from this blog: https://www.tektutorialshub.com/angular/angular-canonical-url/
  ) {}

  setMeta(title: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
  }

  setOgTag(title: string, descriptions: string, logo: any='') {
    this.meta.updateTag({
      property: 'og:title',
      content: title,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: descriptions,
    });

    if(logo){
      this.meta.updateTag({
        property: 'og:image',
        content: logo,
      });
    } else {
      this.meta.removeTag('property="og:image"')
    }

    this.meta.updateTag({ property: 'og:type', content: 'website' });

    const route = this.router.url === '/' ? '' : this.router.url

    let ogUrl = `https://www.gurunearme.com${route}`;
    if(this.dom.location.origin.includes('newui')){
      ogUrl = `https://newui.gurunearme.com${route}`;
    }

    this.meta.updateTag({ property: 'og:url', content: ogUrl });

  }

  setTwitterCardTag(title: string, descriptions: string, logo: any='') {
    this.meta.updateTag({
      property: 'twitter:title',
      content: title,
    });
    this.meta.updateTag({
      property: 'twitter:description',
      content: descriptions,
    });

    if(logo){
      this.meta.updateTag({
        property: 'twitter:image',
        content: logo,
      });
    } else {
      this.meta.removeTag('property="twitter:image"')
    }
    this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
  }

  getMeta() {
    return this.http.get<any>('assets/meta.json').toPromise();
  }
}
