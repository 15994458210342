import { Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { InstituteByUrlCode, InstituteService } from '@/app/services/institute.service'
import Institute from '../interface/institute';
import { NgbAccordionModule, NgbCarousel, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import Faq from '../interface/faq';

import { ContactFormComponent } from '../shared/contact-form/contact-form.component';
import { TrendingSearchesComponent } from '../shared/trending-searches/trending-searches.component';
import { PopularCardComponent } from '../shared/popular-card/popular-card.component';

import { RouterLink } from '@angular/router';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { LocationService } from '../services/location.service';
import { environment } from '@/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe, DOCUMENT, isPlatformBrowser, KeyValuePipe, NgTemplateOutlet } from '@angular/common';
import { MetaService } from '../services/meta.service';
import { forkJoin, map, Observable, of } from 'rxjs';
import { normalizeTypeName } from '../utils/string';
import { HttpParams } from '@angular/common/http';
import { FaqComponent } from '../shared/faq/faq.component';
import Batch from '../interface/batch';
import { MobileSearchComponent } from "../mobile-search/mobile-search.component";
import { ClaimListingComponent } from "../claim-listing/claim-listing.component";
import { PageNotFoundComponent } from "../error/error.component";

@Component({
  selector: 'app-institute-detail',
  standalone: true,
  imports: [
    AppHeaderComponent,
    AppFooterComponent,
    TrendingSearchesComponent,
    PopularCardComponent,
    RouterLink,
    NgbCarouselModule,
    NgbAccordionModule,
    GoogleMap,
    MapMarker,
    ContactFormComponent,
    NgTemplateOutlet,
    AsyncPipe,
    FaqComponent,
    KeyValuePipe,
    MobileSearchComponent,
    ClaimListingComponent,
    PageNotFoundComponent
],
  templateUrl: './institute-detail.component.html',
  styleUrl: './institute-detail.component.scss',
})

export class InstituteDetailComponent implements OnChanges {
  // Inputs
  @Input() urlCode!: string;

  // Public variables of the class
  instituteData: InstituteByUrlCode = null
  instituteObj: Institute = null
  faqList: Faq[] = []
  showDirectionsButton = true
  isPageNotFound = false; 
  directionsButtonLabel = 'Get Directions'
  teacherExperienceText = ''
  environment = environment;
  batchData: { [key: string]: any[] } = {};
  classMap: { [key: string]: string } = {};
  mapOptions: google.maps.MapOptions = {}
  instituteLatLng: google.maps.LatLngLiteral
  relatedCategories$: Observable<any[]>
  categoriesByLocality$: Observable<any[]>
  relatedInstitutes$: Observable<any[]>

  @ViewChild('instituteGallery') carousel: NgbCarousel;

  constructor(
    private instituteService:InstituteService,
    private locationService: LocationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService:MetaService,
    @Inject(DOCUMENT) private dom:Document, // In SSR browser's `document` variable is not available. So, use this instead. Taken from this blog: https://www.tektutorialshub.com/angular/angular-canonical-url/
    @Inject(PLATFORM_ID) private platformId: Object
  ){}

  async ngOnChanges(changes: SimpleChanges) {
    // Get institute data from the server
    try{
    this.instituteData = await this.instituteService.getInstituteDetail(this.urlCode)
    }catch(e:any){
      if (e.status === 400) {
       this.isPageNotFound = true; 
      }
    }
    this.instituteObj = this.instituteData.institute

    // When clicks on `Contact` button on listing page, we want to send him to Contact Form on detail page
    // Since the form is not visible immediately, we scroll to it after 1 second. 
    setTimeout(() => {
      const fragment = this.activatedRoute.snapshot.fragment;
        if (fragment) {
          const element = this.dom.getElementById(fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }          
      }
    }, 1000)

    if(isPlatformBrowser(this.platformId)){
      // The variable `google` is not available on the server platform, so, only call these functions when browser is the platform

      // Initialize Map
      await this.importMapLibraries()
      this.initializeMapOptions()
    }

    // Calculate the visibility of `Directions` button
    this.calculateDirectionsVisibility()

    // Calculate the label of `Directions` button
    // this.calculateDirectionsLabel()

    // Calculate teacher count and experience text
    this.generateTeacherAndExperienceText()

    // Generate Batches table data
    this.generateBatchesData()

    this.metaInfo()
    this.generateRelatedLinks()
  }

  calculateDirectionsLabel(){
    // Once the institute detail API starts sending `distance`, change the logic in this method to set the value of `directionsButtonLabel`  
  }

  calculateDirectionsVisibility(){
    // If admins by mistake set value to zero, we need to hide the Directions button because the maps won't work
    const {coordinates} = this.instituteObj.location
    if(coordinates[1] === 0){
      this.showDirectionsButton = false
    }
  }

  generateTeacherAndExperienceText(){
    this.teacherExperienceText =  this.instituteService.getTeacherAndExperienceText(
      this.instituteData.teachersData.length,
      this.instituteData.experienceMin, 
      this.instituteData.experienceMax 
    )
  }

  onDirectionsClick(){
    let url;
    const {coordinates} = this.instituteObj.location

    if (this.locationService.permissionState == 'granted') {
      // If location of the user is available, create URL that shows direction from user's location
      const {latitude: userLatitude, longitude: userLongitude} = this.locationService
      url = `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${coordinates[1]},${coordinates[0]}`;
    } else {
      // If location is not available, simply open the institute location on Google maps.
      url = `https://www.google.com/maps/dir/?api=1&&destination=${coordinates[1]},${coordinates[0]}`;
    }
    window.open(url, '_blank');
  }

  selectImage(imageObj: any){
    this.carousel.select(imageObj._id)
  }

  generateBatchesData(){
    this.batchData = this.instituteData.batches.reduce((acc:any, item:Batch) => {
        const classId = item.class._id;
    
        if (!acc[classId]) {
            acc[classId] = [];
        }

        if(!this.classMap.hasOwnProperty(classId)){
          this.classMap[classId] = item.class.name
        }
    

        acc[classId].push({
          ...item,
          formattedStartTime: item.startTime? this.convertTimestamp(item.startTime): 'NA',
          formattedEndTime: item.endTime ? this.convertTimestamp(item.endTime): 'NA'

        });
        return acc;
    }, {});

  }

  convertTimestamp(time: any){
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  async importMapLibraries(){
    await google.maps.importLibrary("maps"); // Needed to access google.maps.MapTypeId in `initializeMapOptions`
  }

  initializeMapOptions(){
    const [instituteLong, instituteLat] = this.instituteObj.location.coordinates
    this.instituteLatLng = {lat: instituteLat, lng: instituteLong}

    this.mapOptions = {
      zoom: 14,
      center: this.instituteLatLng,
      disableDefaultUI: true,
      scrollwheel: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'roadatlas'],
      },
    };
  }

  shareViaWhatsApp(){
    const text = `Know about ${this.instituteObj.name} on GuruNearMe.`
    const instituteURL = `https://www.gurunearme.com${this.router.url}`
    const encodedText = encodeURIComponent(text+'\n'+instituteURL)

    window.open(
      `https://wa.me/?text=${encodedText}`,
      '_blank'
    );
  }
  
  metaInfo(){
    const intituteTypeNames =this.instituteObj.type.map(type=>{
      return type.type
    })
    const title=this.instituteObj.name+ ' - ' + this.instituteObj.address + ', ' + this.instituteObj.city.name + ', ' + this.instituteObj.state.name +' - ' + this.instituteObj.postalCode;
    let description = this.instituteObj.name+' - '+ intituteTypeNames
    this.metaService.setMeta(title,description)
    const ogTitle=this.instituteObj.name
    const ogDescription=this.instituteObj.address
    const logo= environment.BUCKET_URL+this.instituteObj.logo
    this.metaService.setOgTag(ogTitle,ogDescription,logo)
    this.metaService.setTwitterCardTag(ogTitle,ogDescription,logo)
  }

  generateRelatedLinks(){
    const instituteTypes = this.instituteObj.type
    const instituteCity = this.instituteObj.city

    // Empty the old list before creating new one.
    const relatedCategories: any[] = []
    const relatedInstitutes: any[] = []
    const categoriesByLocality:any[]=[]

    const normalizedCityName = instituteCity.name.toLowerCase()

    const requestsList = instituteTypes.map(typeObj => {
      const normalizedTypeName = normalizeTypeName(typeObj.type)

      // Generate listing page links based on the institute's types and city
      relatedCategories.push({
        text: `${typeObj.type} in ${instituteCity.name}`,
        route: `/listing/${normalizedCityName}/${normalizedTypeName}`,
        queryParams: {
          cityId: instituteCity._id,
          typeId: typeObj._id,
        }
      })
     
      // Generate listing page links based on the institute's types , locality and city
      categoriesByLocality.push({
        text: `${typeObj.type} in ${this.instituteObj.locality.name}, ${instituteCity.name}`,
        route: `/listing/${normalizedCityName}/${normalizedTypeName}`,
        queryParams: {
          cityId: instituteCity._id,
          typeId: typeObj._id,
          localityId:this.instituteObj.locality._id
        }
      })

      // Generate links of top 10 institutes that fall under the same category/type as the current institute
      return this.fetchInstitutesByTypeAndCity(typeObj._id, instituteCity._id)
      .pipe(map(({data}) => {
        const slicedData = data.slice(0,10)
        return slicedData.map((instituteObj: any) => {
          return {
            text: instituteObj.instituteName,
            route: `/institute/${instituteObj.urlCode}`,
            queryParams: {}
          }
        })
      }))

    })


    this.relatedCategories$ = of(relatedCategories)
    this.categoriesByLocality$=of(categoriesByLocality)
    this.relatedInstitutes$ = forkJoin(requestsList).pipe(
      map(lists => lists.flat()) // Flatten the list of lists
    );
  }

  fetchInstitutesByTypeAndCity(typeId: string, cityId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('city', cityId);
    httpParams = httpParams.append('type', typeId);

    return this.instituteService.aggregateInstitutes(httpParams)
  }


}
